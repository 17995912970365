.signIn-Bg {
    background-image: url(../../../assets/Login-Bg.png);
    background-size: cover;
    background-size: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.button {
    margin-top: 30px;
    height: 35px;
    width: 350px;
    position: relative;
    background-color: #87E950;
    position: relative;
    cursor: pointer;
    /* border: 1px solid #064254; */
    border: none;
    overflow: hidden;
    border-radius: 5px;
    color: #333;
    transition: all 0.5s ease-in-out;
  }
  
  .btn-txt {
    z-index: 1;
    font-weight: 800;
  }
  
  .button::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.1s ease-in-out;
    background-color: #333;
    border-radius: 30px;
    visibility: hidden;
    height: 10px;
    width: 10px;
    z-index: -1;
  }
  
  .button:hover {
    box-shadow: 1px 1px 200px #252525;
    color: #87E950;
    border: none;
    background-color: #064254;
  }
  
  .button:hover::after {
    visibility: visible;
    transform: scale(50) translateX(2px);
  }